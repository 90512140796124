import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import administrators from './admin/administrator'

// Office
import users from './seat-management/user'
import teams from './seat-management/team'
import sites from './seat-management/site'
import mailboxes from './seat-management/mailbox'
import backupsettings from './seat-management/backupsettings'
import office from "@/store/app/office";

// Google
import google from '@/store/app/google'

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)


const Store = new Vuex.Store({

  modules: {
    auth,
    users,
    teams,
    sites,
    mailboxes,
    backupsettings,
    administrators,
    office,
    google
  },
  plugins: [createPersistedState({
    key: 'idrive',
    paths: ['auth.accessToken'],
  })],
  mutations:{
    setProviderTenant(state, providerTenant) {
      state.providerTenant = providerTenant
      state.providerUrl = providerTenant === 'GOOGLE' ? 'google':'office'
    },
    setCollection(state, collection) {
      state.collection = collection;
    }
  },
  state:{
    providerTenant:null,
    providerUrl:null,
    collection:null,
  }
})

export default Store;

export {Store} ;

